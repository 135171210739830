import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import moment from 'moment';

const electron = window?.electron;
let htimer = null;
const App = () => {
  const [maxPrice, setMaxPrice] = useState('최고가');
  const [bidInfo, setBidInfo] = useState([]);
  const [bidhistory, setBidHistory] = useState([]);
  const [lastTime, setLastTime] = useState('2024-05-18 08:37:00');
  const [bidTime, setBidTime] = useState('');
  const [bidDate, setBidDate] = useState('');
  const [itemno, setItemno] = useState('');
  const [item, setItem] = useState({});
  const [plusBidInfo, setPlusBidInfo] = useState({index: 1});
  const [plusBidInfo2, setPlusBidInfo2] = useState({ index: 2 });
  const [plusBidInfo3, setPlusBidInfo3] = useState({ index: 3 });
  const [error, setError] = useState(null);
  const [isServer, setIsServer] = useState(null);
  const [siteType, setSiteType] = useState('');
  // const [timer, setTimer] = useState(Array(10).fill(null));

  const lastTimeRef = useRef(lastTime);
  const lastDateRef = useRef(lastTime);
  const itemnoRef = useRef(itemno);
  const bidInfoRef = useRef(bidInfo);
  const siteTypeRef = useRef(siteType);

  const bidTimeRef = useRef(bidTime);
  const bidDateRef = useRef(bidDate);

  useEffect(() => {
    bidTimeRef.current = bidTime;
  }, [bidTime]);

  useEffect(() => {
    bidDateRef.current = bidDate;
  }, [bidDate]);

  useEffect(() => {
    siteTypeRef.current = siteType;
  }, [siteType]);

  useEffect(() => {
    bidInfoRef.current = bidInfo;
  }, [bidInfo]);

  useEffect(() => {
    itemnoRef.current = itemno;
  }, [itemno]);

  const setBidRef = useRef((data, type, date) => {
    console.log(data);
    console.log(type, moment(date).format('mm:ss.SSS'));
    if (siteTypeRef.current === 'auction') {
      electron?.ipcRenderer.send('set-bid', data);
    } else if (siteTypeRef.current === 'roki') {
      electron?.ipcRenderer.send('set-bid-roki', data);
    } else if (siteTypeRef.current === 'jjm') {
      console.log('set-bid-jjm')
      electron?.ipcRenderer.send('set-bid-jjm', data);
    }
  });

  const calculateTimeLeft = () => {
    const now = new Date();
    const deadline = new Date(lastDateRef.current);
    const timeRemaining = deadline - now;

    if (now.getTime() % 3000 === 0) {
      if (siteTypeRef.current === 'auction') {
        electron?.ipcRenderer.send('get-item', itemnoRef.current);
      }
    }

    if (plusBidInfo.checked === 1 && plusBidInfo.time && plusBidInfo.timestamp
      && plusBidInfo.timestamp <= now.getTime()) {
        plusBidInfo.checked = 2;
        setPlusBidInfo(plusBidInfo)
        setBidRef.current(plusBidInfo, 'plusBid', new Date());
    }
    if (plusBidInfo2.checked === 1 && plusBidInfo2.time && plusBidInfo2.timestamp
      && plusBidInfo2.timestamp <= now.getTime()) {
        plusBidInfo2.checked = 2;
        setPlusBidInfo2(plusBidInfo2)
        setBidRef.current(plusBidInfo2, 'plusBid', new Date());
    }
    if (plusBidInfo3.checked === 1 && plusBidInfo3.time && plusBidInfo3.timestamp
      && plusBidInfo3.timestamp <= now.getTime()) {
        plusBidInfo3.checked = 2;
        setPlusBidInfo3(plusBidInfo3)
        setBidRef.current(plusBidInfo3, 'plusBid', new Date());
    }

    bidInfo.map((data, i) => {
      if (data.checked === 1 && data.time && data.price && data.timestamp
         && data.timestamp <= now.getTime()) {
            data.checked = 2;
          console.log('입찰!', data,
            moment(data.timestamp, 'x').format('mm:ss.SSS'),
            moment(now.getTime()).format('mm:ss.SSS'), 
            moment(new Date()).format('mm:ss.SSS'))
          setBidRef.current(data, 'bid', new Date());
         }
      return data
    });

    if (timeRemaining > 0) {
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
      const milliSeconds = Math.floor(timeRemaining % 1000);

      return {
        days,
        hours,
        minutes,
        seconds,
        milliSeconds
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliSeconds: 0
      };
    }
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    let data = [];
    for (let i = 0; i < 8; i ++) {
      data.push({
        checked: 0,
        time: '',
        price: ''
      });
    }

    // electron?.ipcRenderer.send('open-auction', 'test');
    electron?.ipcRenderer.on('set-bid', (result) => {
      console.log(result)
    });

    electron?.ipcRenderer.on('set-itemno', (itemno) => {
      console.log('set-itemno itemno', itemno)
      setItemno(itemno)
    });


    electron?.ipcRenderer.on('get-item', (item) => {
      console.log('get-item', siteTypeRef.current, item)
      if (!item) return
      if (siteTypeRef.current === 'auction') {
        setItemno(item.$.ItemID);
        setLastTime(item.ListingDetails.$.EndTime);
        lastDateRef.current = item.ListingDetails.$.EndTime;
        // lastTime 보다 1초 적게 bidTime 에 넣어줘
        if (bidDateRef.current === '') {
          lastTimeRef.current = item.ListingDetails.$.EndTime
          setBidDate(moment(lastTimeRef.current).subtract(1, 'second').format('YYYY-MM-DD'));
          setBidTime(moment(lastTimeRef.current).subtract(1, 'second').format('HH:mm:ss'));
        }
      } else if (siteTypeRef.current === 'roki') {
        setLastTime(item.EndTime);
        lastDateRef.current = item.EndTime;
        if (bidDateRef.current === '') {
          lastTimeRef.current = item.EndTime
          setBidDate(moment(lastTimeRef.current).subtract(1, 'second').format('YYYY-MM-DD'));
          setBidTime(moment(lastTimeRef.current).subtract(1, 'second').format('HH:mm:ss'));
        }
      }

      setItem(item);
    });

    electron?.ipcRenderer.on('get-history', (list) => {
      if (!list) return
      if (!Array.isArray(list) && list?.$) list = [list];
      console.log('get-history', list.length)
      setBidHistory(list);
    });

    electron?.ipcRenderer.on('get-headers', (data) => {
      console.log('get-headers', data)

      if (data.type === 'nowBid') {
        electron?.ipcRenderer.send('now-bid', data);
      } else if (data.type === 'plusBid') {
        if (data.index === 1) {
          setPlusBidInfo(data)
        } else if (data.index === 2) {
          setPlusBidInfo2(data)
        } else if (data.index === 3) {
          setPlusBidInfo3(data)
        }
      } else {
        let temp = [...bidInfoRef.current];
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].time === data.time && temp[i].price === data.price) {
            temp[i] = data
          }
        }

        console.log(temp)

        setBidInfo(temp);
      }

    });

    setBidInfo(data);
    return () => {
      clearInterval(htimer);
    }
  }, []);

  // console.log(bidInfo)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1);

    return () => clearInterval(timer);
  }, [item]);


  // x 버튼 클릭 시 input 내용 삭제
  const handleClearInput = (index) => {
    const newBidInfo = [...bidInfo];
    newBidInfo[index] = {time: '', price: '', checked: 0};
    setBidInfo([...newBidInfo]);
  };

  // const setBid = (price, type, date) => {
  //   electron?.ipcRenderer.send('set-bid', { price, type, date });
  // }

  return (
    <div className="app" style={{ display: 'flex' }}>
      {/* app 클래스 적용 */}
      <div style={{width: 400}}>
        <div className="max-price-container">
          <div className='site-container' >
            <div className="max-price-btns" style={{ paddingBottom: 0, marginRight: 5 }}
              onClick={() => {
                setSiteType('auction')
                electron?.ipcRenderer.send('open-auction', '');
              }}
            >
              <img src="/auction.png" alt="auction" style={{ width: 30, height: 30, cursor: 'pointer' }} />
            </div>
            <div className="max-price-btns" style={{ paddingBottom: 0, marginRight: 5 }}
              onClick={() => {
                setSiteType('roki')
                console.log('open-roki')
                electron?.ipcRenderer.send('open-roki', '');
              }}
            >
              <img src="/roki.png" alt="auction" style={{ width: 30, height: 30, cursor: 'pointer' }} />
            </div>
            <div className="max-price-btns" style={{ paddingBottom: 0, marginRight: 5 }}
              onClick={() => {
                setSiteType('jjm')
                console.log('open-jjm')
                electron?.ipcRenderer.send('open-jjm', '');
              }}
            >
              <img src="/jjm.png" alt="auction" style={{ width: 30, height: 30, cursor: 'pointer' }} />
            </div>
          </div>
          <input
            type="text"
            className="max-price-input"
            style={{ width: 120 }}
            placeholder="최고가"
            value={maxPrice}
            onChange={e => setMaxPrice(e.target.value)}
            //숫자 이외의 값은 제거
            onInput={e => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))}
          />
          <button className="instant-bid-btn"
            onClick={() => {
              if (siteType === 'auction') {
                electron?.ipcRenderer.send('get-headers', {
                  itemno: itemno,
                  price: 0,
                  type: 'nowBid',
                });
              } else if (siteType === 'roki') { 
                electron?.ipcRenderer.send('set-bid-roki', {
                  itemno: itemno,
                  price: 1000,
                  type: 'nowBid',
                });
              } else if (siteType === 'jjm') { 
                console.log('set-bid-jjm')
                electron?.ipcRenderer.send('set-bid-jjm', {
                  // itemno: itemno,
                  price: 1,
                  type: 'nowBid',
                });
              }
            }}
          >즉시입찰</button>
        </div>
        <div className="max-price-container" style={{marginTop: -15}}>
          <button
            onClick={() => {
              alert('서버정리 완료되었습니다.')
            }}
          >서버정리</button>
          <input
            style={{
              paddingLeft: 8,
              border: '2px solid blue',
              borderRadius: 5,
              width: 85,
              textAlign: 'left',
              height: 30,
              marginBottom: 0
            }}
            type="text"
            placeholder={'입찰날짜'}
            value={bidDate}
            onChange={(e) => {
              setBidDate(e.target.value);
            }}
          />
          <input
            style={{
              paddingLeft: 8,
              border: '2px solid blue',
              borderRadius: 5,
              width: 85,
              textAlign: 'left',
              height: 30,
              marginBottom: 0
            }}
            type="text"
            placeholder={'입찰시간'}
            value={bidTime}
            onChange={(e) => {
              setBidTime(e.target.value);
            }}
          />
          <button className="place-bid-btn5 instant-bid-btn"
            style={{ width: 80, marginTop: 0 }}
            onClick={() => {
              // 각각 입찰시간과 입찰금액이 입력 되어 있으면, 입찰내역에 추가, 해당 체크박스 index 에 체크
              bidInfo.forEach((data, index) => {
                data.checked = 0;
              });
              setBidInfo([...bidInfo]);
              setPlusBidInfo({...plusBidInfo, checked: 0});
              setPlusBidInfo2({ ...plusBidInfo2, checked: 0 });
              setPlusBidInfo3({ ...plusBidInfo3, checked: 0 });
            }}
          >입찰취소</button>
        </div>

        <div className="bid-inputs-container" style={{marginTop: -10}}>
          <div className="bid-inputs" style={{ justifyContent: 'space-between' }}>
            <label>공차 입찰하기</label>
          </div>
          <div className="bid-inputs" >
            <div style={{ width: 40 }}>
              <input type="checkbox" className={'checkbox' + (plusBidInfo?.checked || '')} disabled
                checked={plusBidInfo?.checked > 0}
               />
            </div>
            <input type="text" style={{width: 80}} placeholder="입찰시간" 
              value={plusBidInfo?.time || null}
            onChange={(e) => {
              setPlusBidInfo({...plusBidInfo, time: e.target.value})
            }}/>
            <input type="text" style={{ width: 80 }} placeholder="+금액"
              value={plusBidInfo?.price || null}
            onChange={(e) => {
              setPlusBidInfo({ ...plusBidInfo, price: e.target.value })
            }} />

            <button className="place-bid-btn"
              onClick={() => {
                if (siteType === 'auction') {
                  electron?.ipcRenderer.send('get-headers', {
                    index: 1,
                    type: 'plusBid',
                    maxPrice: parseInt(maxPrice) || 0,
                    time: parseInt(plusBidInfo.time),
                    price: parseInt(plusBidInfo.price),
                    itemno: itemno,
                    checked: 1,
                    timestamp: parseInt(moment(bidDate + ' ' + bidTime).unix() * 1000) + parseInt(plusBidInfo.time)
                  });
                  electron?.ipcRenderer.send('get-headers', {
                    index: 2,
                    type: 'plusBid',
                    maxPrice: parseInt(maxPrice) || 0,
                    time: parseInt(plusBidInfo2.time),
                    price: parseInt(plusBidInfo2.price),
                    itemno: itemno,
                    checked: 1,
                    timestamp: parseInt(moment(bidDate + ' ' + bidTime).unix() * 1000) + parseInt(plusBidInfo2.time)
                  });
                  electron?.ipcRenderer.send('get-headers', {
                    index: 3,
                    type: 'plusBid',
                    maxPrice: parseInt(maxPrice) || 0,
                    time: parseInt(plusBidInfo3.time),
                    price: parseInt(plusBidInfo3.price),
                    itemno: itemno,
                    checked: 1,
                    timestamp: parseInt(moment(bidDate + ' ' + bidTime).unix() * 1000) + parseInt(plusBidInfo3.time)
                  });
                } else if (siteType === 'jjm') {
                  setPlusBidInfo({
                    index: 1,
                    type: 'plusBid',
                    maxPrice: parseInt(maxPrice) || 0,
                    time: parseInt(plusBidInfo?.time || 0),
                    price: parseInt(plusBidInfo?.price || 0),
                    // itemno: itemno,
                    checked: 1,
                    timestamp: parseInt(moment(bidDate + ' ' + bidTime).unix() * 1000) + parseInt(plusBidInfo.time)
                  });
                } else if (siteType === 'roki') {
                  setPlusBidInfo({
                    index: 1,
                    type: 'plusBid',
                    maxPrice: parseInt(maxPrice) || 0,
                    time: parseInt(plusBidInfo?.time || 0),
                    price: parseInt(plusBidInfo?.price || 0),
                    itemno: itemno,
                    checked: 1,
                    timestamp: parseInt(moment(bidDate + ' ' + bidTime).unix() * 1000) + parseInt(plusBidInfo.time)
                  });
                  console.log('roki', plusBidInfo)
                  setPlusBidInfo2({
                    index: 2,
                    type: 'plusBid',
                    maxPrice: parseInt(maxPrice) || 0,
                    time: parseInt(plusBidInfo2?.time || 0),
                    price: parseInt(plusBidInfo2.price || 0),
                    itemno: itemno,
                    checked: 1,
                    timestamp: parseInt(moment(bidDate + ' ' + bidTime).unix() * 1000) + parseInt(plusBidInfo2.time)
                  });
                  setPlusBidInfo3({
                    index: 3,
                    type: 'plusBid',
                    maxPrice: parseInt(maxPrice) || 0,
                    time: parseInt(plusBidInfo3?.time || 0),
                    price: parseInt(plusBidInfo3?.price || 0),
                    itemno: itemno,
                    checked: 1,
                    timestamp: parseInt(moment(bidDate + ' ' + bidTime).unix() * 1000) + parseInt(plusBidInfo3.time)
                  });
                }
              }}
            >+ 입찰등록</button>
          </div>
          <div className="bid-inputs" >
            <div style={{ width: 40 }}>
              <input type="checkbox" className={'checkbox' + (plusBidInfo2?.checked || '')} disabled
                checked={plusBidInfo2?.checked > 0}
               />
            </div>
            <input type="text" style={{width: 80}} placeholder="입찰시간" 
              value={plusBidInfo2?.time || null}
            onChange={(e) => {
              setPlusBidInfo2({...plusBidInfo2, time: e.target.value})
            }}/>
            <input type="text" style={{ width: 80 }} placeholder="+금액"
              value={plusBidInfo2?.price || null}
            onChange={(e) => {
              setPlusBidInfo2({ ...plusBidInfo2, price: e.target.value })
            }} />
            <div style={{ width: 100 }}></div>
          </div>
          <div className="bid-inputs" >
            <div style={{ width: 40 }}>
              <input type="checkbox" className={'checkbox' + (plusBidInfo3?.checked || '')} disabled
                checked={plusBidInfo3?.checked > 0}
               />
            </div>
            <input type="text" style={{width: 80}} placeholder="입찰시간" 
              value={plusBidInfo3?.time || null}
            onChange={(e) => {
              setPlusBidInfo3({...plusBidInfo3, time: e.target.value})
            }}/>
            <input type="text" style={{ width: 80 }} placeholder="+금액"
              value={plusBidInfo3?.price || null}
            onChange={(e) => {
              setPlusBidInfo3({ ...plusBidInfo3, price: e.target.value })
            }} />
            <div style={{ width: 100 }}></div>
          </div>
        </div>

        <div className="bid-inputs-container" style={{marginTop: -15}}>
          <div className="bid-inputs"/>
            <label style={{ width: 40, textAlign: 'right', color: 'red', fontSize: '0.9rem' }}>{error}</label>
          <div className="bid-inputs" style={{justifyContent: 'space-between'}}>
            <label>금액 입찰하기</label>
            <button className="place-bid-btn2"
              style={{ marginRight: 5 }}
              onClick={() => {
                // 첫번째 입찰시간, 입찰금액과 마지막 입찰시간과 입찰금액이 없으면 없다고 alert
                if (!bidInfo[0].time || !bidInfo[0].price || !bidInfo[bidInfo.length - 1].time || !bidInfo[bidInfo.length - 1].price) {
                  setError('빨간 테두리의 입찰시간과 입찰금액을 모두 입력해주세요.');
                  return;
                } else {
                  setError('');
                }
                // 있으면, 첫 입찰시간 관 마지막 입찰 시간의 등차 간격으로 나머지 입찰시간 채우고,
                // 입찰 금액도 마찬가지로 채워줘
                const newBidInfo = [];
                const start = parseInt(bidInfo[0].time);
                const end = parseInt(bidInfo[bidInfo.length - 1].time);
                const diffTime = parseFloat((end - start) / 9);
                const priceStart = parseInt(bidInfo[0].price);
                const priceEnd = parseInt(bidInfo[bidInfo.length - 1].price);
                const diff = parseInt((priceEnd - priceStart) / 9);
                for (let i = 0; i < bidInfo.length; i ++) {
                  newBidInfo.push({
                    checked: bidInfo[i].checked,
                    time: Math.ceil((start + (diffTime * i))),
                    price: Math.ceil((priceStart + (diff * i)) / 1000) * 1000
                  });
                }
                setBidInfo(newBidInfo);
              }}
            >계산하기</button>
            <button className="place-bid-btn3"
            style={{marginRight: 10}}
            onClick={() => {
              // 각각 입찰시간과 입찰금액이 입력 되어 있으면, 입찰내역에 추가, 해당 체크박스 index 에 체크
              bidInfo.forEach((data, index) => {
                if (data.time && data.price) {
                  // lastTime 은 YYYY-MM-DD HH:mm:ss 인데 이것 과 time (milliseconds) 을 더해서 새로운 시간을 만들어줘
                    data.itemno = itemno
                    if (data.price > parseInt(maxPrice)) {
                      data.checked = 0
                    } else {
                      data.checked = 1
                    }
                    data.maxPrice = parseInt(maxPrice) || 0
                    data.timestamp = moment(bidDate + ' ' + bidTime).unix() * 1000 + parseInt(data.time)
                    data.time = parseInt(data.time)
                    data.price = parseInt(data.price)

                    if (siteType === 'auction') {
                      electron?.ipcRenderer.send('get-headers', data);
                    }
                } else {
                  data.checked = 4
                  data.timestamp = data.timestamp
                  data.time = data.time
                  data.price = data.price
                }
              });
              // setTimer(timer);
              setBidInfo(bidInfo);
            }}
            >입찰등록</button>
          </div>
          { bidInfo.map((data, index) => (
            <div className="bid-inputs" key={index}>
              <label style={{width: 20, textAlign: 'right'}}>{index + 1}</label>
              <div style={{width: 40}}>
                <input type="checkbox" 
                  className={'checkbox'+bidInfo[index].checked}
                  disabled checked={bidInfo[index].checked > 0} />
              </div>
              <input
                style={{
                  border: index === 0 || index === bidInfo.length - 1 ? '2px solid red' : '1px solid #acacac',
                  borderRadius: 5,
                  width: 100,
                  textAlign: 'center'
                }}
                type="text"
                placeholder={'입찰시간'} 
                value={data.time}
                onChange={(e) => {
                  const newBidInfo = [...bidInfo];
                  newBidInfo[index] = {...data, time: e.target.value};
                  setBidInfo([...newBidInfo]);
                }}
              />
              <input 
                style={{
                  border: index === 0 ? '2px solid red' : index === bidInfo.length - 1 ? '2px solid red' : '1px solid #acacac',
                  borderRadius: 5,
                  width: 100,
                  textAlign: 'center'
                }}
                type="text"
                placeholder={'입찰금액'} 
                value={data.price} 
                onChange={e => {
                  const newBidInfo = [...bidInfo];
                  newBidInfo[index] = {...data, price: e.target.value};
                  setBidInfo([...newBidInfo]);
                }} />
              <button
                style={{
                  marginLeft: '5px',
                  marginRight: '0px',
                  background: 'rgba(0, 0, 220, 0.3)', // 유리톤 배경색
                  border: 'none',
                  cursor: 'pointer',
                  padding: '7px 10px',
                  borderRadius: '50%', // 원형 모양으로
                  fontSize: '0.9em', // 크기 조절
                  color: '#fff' // 텍스트 색상
                }}
                onClick={() => handleClearInput(index)}
              >
                X
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="scrollybase bidscroll mtxxs" style={{width: 450, maxHeight: 680, marginLeft: 10, lineHeight: 1.5}}>
        {item?.$ && <div className="info" style={{display: 'flex'}}>
          <div className="image">
            <div className="big01">
              <img src={'https://image.auction.co.kr/itemimage' + item?.PictureDetails?.PictureUrl} id="ucImageNavigator_himgBig" width="80" height="80" style={{display: 'inline'}} alt='t' />
            </div>
            <div className="vmenuv301" id="divMagnify" style={{textAlign: 'center', display: 'block'}}></div>
          </div>
          <div className="detail" style={{marginLeft: 10}}>
            <div className="titlev3">
              <em className="first" style={{ fontSize: '0.8rem' }}>상품번호 : {itemno}</em>
              <h2 id="hdivItemTitle" style={{fontSize: '0.9rem', marginTop: -4}}>{item.$.Name}</h2>
            </div>
            <div className="redprice" style={{ display: 'flex', marginTop: -7 }}>
              <div className="present_price" id="Price" style={{ fontSize: '0.9rem', marginTop: -4 }}>
                현재가 <span className="present_num">{parseInt(item.$.NowPrice).toLocaleString()}</span>원 </div>
              <div className="point" style={{ fontSize: '0.9rem', marginTop: -4 }}><span className="sf fc6"> 
              (시작가 <span className="num_thm">{parseInt(item.$.StartPrice).toLocaleString()}</span>원</span>)</div>
            </div>
            <dl className="nv3" style={{ fontSize: '0.9rem', marginTop: -4 }}>
              <div><span className="fss">입찰수<span className="num_thm"> {bidhistory.length} </span>회 </span></div>
              <div><span className="fss" id="txtLeftPeriod" style={{width: 200}}>{timeLeft.days > 0 ? timeLeft.days + '일 ' : ''} 
                {timeLeft.hours}시 {timeLeft.minutes}분 {timeLeft.seconds}초 .{timeLeft.milliSeconds.toString().padStart(3, '0')} 
              (종료 : {moment(item.ListingDetails.$.EndTime).format('YY-MM-DD HH:mm:ss')})</span></div>
            </dl>
          </div>
        </div>}
        <div className="bid_tbl1">
          <button className="place-bid-btn5 instant-bid-btn"
            style={{ width: 80, marginTop: 0 }}
            onClick={() => {
              electron?.ipcRenderer.send('get-item', itemnoRef.current);
            }}
          >새로고침</button>
        </div>
        <table cellPadding="0" cellSpacing="0" className="bid_tbl2">
          <colgroup>
            <col width="5%" />
            <col width="27%" />
            <col width="36%" />
            <col width="27%" />
          </colgroup>
          <tbody>
            <tr>
              <th>Rank</th>
              <th>입찰자 ID</th>
              <th>입찰일자</th>
              <th>입찰가격</th>
            </tr>
            {bidhistory.map((_, i) => (
              <tr key={i}>
                <td>{ i + 1 }</td>
                <td className="bidid">
                  {_.$.BuyerID}
                </td>
                <td>{moment(_.$.BidDate).format('YY-MM-DD HH:mm:ss.SSS')}</td>
                <td>{parseInt(_.$.Price).toLocaleString()} 원</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default App;
